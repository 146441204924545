.content {
    width: 60%;
    height: 50%;
    margin: 5% 50px 50px 50px;
}

.contentTitle {
    margin-bottom: 10px;
    font-size: 36px;
}

.body {
    margin-left: 3px;
}
